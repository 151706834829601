export const MANAGER_USER_LOGIN_METHOD = {
  user: [
    {
      text: "免密登录",
      key: "phoneNumberWay",
    },
    {
      text: "微信扫码",
      key: "scancodeLogin",
    },
  ],
  manager: [
    {
      text: "密码登录",
      key: "passwordWay",
    },
  ],
  bindPhone: [
    {
      text: "绑定手机号",
      key: "bindPhoneNumber",
    },
  ],
};
